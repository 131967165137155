import { Box, createStyles, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { CipherOutputComponentProps } from "../../common/CipherPage";
import toText from "../../helpers/morse/toText";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      wordBreak: "break-word",
    },
  }),
);

const FromMorseOutput: React.FC<CipherOutputComponentProps> = ({ textToTranslate }) => {
  const classes = useStyles();

  return (
    <Typography variant="body1" component="div" className={classes.root}>
      <Box fontWeight="fontWeightBold" letterSpacing={4}>
        {toText(textToTranslate)}
      </Box>
    </Typography>
  );
};

export default FromMorseOutput;
