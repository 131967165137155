import { Container, createStyles, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useState } from "react";
import BrailleOutput from "../ciphers/braille/BrailleOutput";
import LetterNumberOutput from "../ciphers/letter-number/LetterNumberOutput";
import FromMorseOutput from "../ciphers/morse/FromMorseOutput";
import MorseOutput from "../ciphers/morse/MorseOutput";
import PigpenOutput from "../ciphers/pigpen/PigpenOutput";
import SemaphoreOutput from "../ciphers/semaphore/SemaphoreOutput";
import SignalFlagsOutput from "../ciphers/signal-flags/SignalFlagsOutput";
import { inputFilterFunction, nullFunction } from "../helpers/utils";
import { useIDBValue } from "../hooks/useIDBValue";
import { SectionType } from "../pages/app-types";
import SwitchDirectionBar from "./SwitchDirectionBar";
import TextInput from "./TextInput";

const useStyles = makeStyles(theme =>
  createStyles({
    output: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      minHeight: "100px",
      position: "relative",
    },
    paper: {
      minHeight: "inherit",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      position: "relative",
    },
    componentWrapper: {
      maxHeight: "200px",
      overflow: "auto",
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(4),
    },
    fakeDiv: {
      position: "absolute",
      bottom: "0",
      left: 0,
      width: "100%",
      height: theme.spacing(4),
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${theme.palette.primary.main} 100%)`,
    },
  }),
);

export interface CipherOutputComponentProps {
  textToTranslate: string;
}

const outputComponentMap: { [key in "from" | "to"]: { [key in SectionType]: React.FC<CipherOutputComponentProps> } } = {
  from: {
    morse: MorseOutput,
    braille: BrailleOutput,
    "letter-number": LetterNumberOutput,
    semaphore: SemaphoreOutput,
    pigpen: PigpenOutput,
    "signal-flags": SignalFlagsOutput,
  },
  to: {
    morse: FromMorseOutput,
    braille: nullFunction,
    "letter-number": nullFunction,
    semaphore: nullFunction,
    pigpen: nullFunction,
    "signal-flags": nullFunction,
  },
};

interface CipherPageProps {
  sectionType: SectionType;
}

const CipherPage: React.FC<CipherPageProps> = ({ sectionType }) => {
  const classes = useStyles();

  const { value: fromText, setValue: setFromText } = useIDBValue("fromText", true);
  const { value: text, setValue: setText } = useIDBValue("text", "");

  const filterFunction = inputFilterFunction[fromText ? "from" : "to"][sectionType];

  useEffect(() => {
    setText(filterFunction(text));
  }, [filterFunction, text, setText]);

  const handleChangeDirection = () => setFromText(!fromText);
  const handleTextChange = useCallback((value: string) => setText(filterFunction(value)), [filterFunction]);

  const Component = outputComponentMap[fromText ? "from" : "to"][sectionType];
  return (
    <>
      <Paper square elevation={3}>
        <SwitchDirectionBar sectionType={sectionType} fromText={fromText} onChangeDirection={handleChangeDirection} />
        <TextInput value={text} onChange={handleTextChange} />
      </Paper>
      <Container className={classes.output}>
        <Paper className={classes.paper} square>
          <div className={classes.componentWrapper}>
            <Component textToTranslate={text} />
          </div>
          <div className={classes.fakeDiv} />
        </Paper>
      </Container>
    </>
  );
};

export default CipherPage;
