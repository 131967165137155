import { get, set } from "idb-keyval";
import { useCallback, useEffect, useState } from "react";

export const useIDBValue = <T>(idb_name: string, defaultValue: T) => {
  const [value, setInternValue] = useState(defaultValue);

  useEffect(() => {
    get<T>(idb_name).then(idb_value => {
      if (idb_value !== undefined) setInternValue(idb_value);
    });
  }, [idb_name]);

  const setValue = useCallback(
    (newValue: T) => {
      set(idb_name, newValue);
      setInternValue(newValue);
    },
    [idb_name],
  );

  return { value, setValue };
};
