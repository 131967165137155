import { lightBlue } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

const darkPalette: PaletteOptions = {
  type: "dark",
  primary: {
    light: "#b6ffff",
    main: lightBlue["200"],
    dark: "#4ba3c7",
  },
  secondary: {
    light: "#ffd9b1",
    main: "#faa781",
    dark: "#c47854",
  },
};

const lightPalette: PaletteOptions = {
  type: "light",
  primary: {
    light: "#67daff",
    main: lightBlue["500"],
    dark: "#007ac1",
    contrastText: "#ffffff",
  },
  secondary: {
    light: "#ff853e",
    main: "#f45207",
    dark: "#b91700",
  },
};

// A custom theme for this app
const theme = (dark: boolean) =>
  createMuiTheme({
    palette: dark ? darkPalette : lightPalette,
  });

export default theme;
