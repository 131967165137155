import { createStyles, Divider, IconButton, Paper, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { SectionType } from "../pages/app-types";

const useStyles = makeStyles(() =>
  createStyles({
    fromText: {
      "& > $text": {
        margin: "0 25px 0 0",
        order: 0,
        textAlign: "left",
      },
      "& > $codeName": {
        margin: "0 0 0 25px",
        order: 2,
        textAlign: "right",
      },
      "& > $switch": {
        order: 1,
      },
    },
    text: {
      width: "50%",
      marginLeft: "25px",
      textAlign: "right",
    },
    codeName: {
      width: "50%",
      marginRight: "25px",
      textAlign: "left",
    },
    switch: {
      margin: "0 -25px",
    },
  }),
);

interface SwitchDirectionBarProps {
  sectionType: SectionType;
  fromText: boolean;
  onChangeDirection: () => void;
}

const SwitchDirectionBar: React.FC<SwitchDirectionBarProps> = ({ sectionType, fromText, onChangeDirection }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper elevation={0} square>
      <Toolbar variant="dense" className={cx({ [classes.fromText]: fromText })}>
        <Typography component="h2" variant="subtitle1" className={classes.codeName}>
          {t(`common.switchDirectionBar.titles.${sectionType}`)}
        </Typography>
        <IconButton color="primary" onClick={onChangeDirection} className={classes.switch}>
          <SwapHorizIcon />
        </IconButton>
        <Typography component="h2" variant="subtitle1" className={classes.text}>
          {t("common.switchDirectionBar.text")}
        </Typography>
      </Toolbar>
      <Divider />
    </Paper>
  );
};

export default SwitchDirectionBar;
