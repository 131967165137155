import { CHARACTER_TO_NUMBER, I_BASE } from "./constants";

const MAP = {
  ...I_BASE.letters,
  ...I_BASE.numbers,
  ...I_BASE.punctuation,
};

const toText = (inputString: string) => {
  const input = validateMorseCipherInput(inputString);

  const result = input.split("/").map(letter => {
    const bin_number = ["-", ...letter].map(char => CHARACTER_TO_NUMBER[char]).join("");
    const number = parseInt(bin_number, 2);
    if (number in MAP) {
      return MAP[number];
    }
    return " ";
  });
  return result.join("");
};

export const validateMorseCipherInput = (value: string) => {
  return [...value]
    .filter(char => char === "." || char === "-" || char === "/" || char === " ")
    .join("")
    .replace(/ /g, "/");
};

export default toText;
