import { Toolbar } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Brightness2Icon from "@material-ui/icons/Brightness2";
import MenuIcon from "@material-ui/icons/Menu";
import { get, set } from "idb-keyval";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { notImplementedYet } from "../helpers/utils";
import { useStore } from "../store-provider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    darkModeIcon: {
      transform: "rotate(20deg)",
    },
  }),
);

interface TopBarProps {
  codePage?: boolean;
}

const TopBar: React.FC<TopBarProps> = ({ codePage = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    state: { darkMode },
    dispatch,
  } = useStore();

  useEffect(() => {
    get<boolean>("darkMode").then(value => {
      if (value !== undefined) dispatch({ type: "SET_DARK_MODE", data: value });
    });
  }, [dispatch]);

  const handleMoonClick = useCallback(() => {
    const newDarkMode = !darkMode;
    dispatch({ type: "SET_DARK_MODE", data: newDarkMode });
    set("darkMode", newDarkMode);
  }, [darkMode, dispatch]);

  return (
    <div className={classes.root}>
      <AppBar position="static" {...(codePage && { elevation: 0 })}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={notImplementedYet}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t("app.name")}
          </Typography>
          <Brightness2Icon onClick={handleMoonClick} className={classes.darkModeIcon} />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopBar;
