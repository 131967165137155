import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AppWrapper from "./AppWrapper";
import "./i18n";
import { StoreProvider } from "./store-provider";

const EntryPoint = () => {
  return (
    <StoreProvider>
      <Router>
        <Route path="/">
          <AppWrapper />
        </Route>
      </Router>
    </StoreProvider>
  );
};

export default EntryPoint;
