import { SectionType } from "./pages/app-types";

const codeRoutes: { [key in SectionType]: string } = {
  morse: "/morse",
  braille: "/braille",
  "letter-number": "/letter-number",
  semaphore: "/semaphore",
  pigpen: "/pigpen",
  "signal-flags": "/signal-flags",
};

const routes = {
  HOME: "/home",
  ...codeRoutes,
};

export default routes;
