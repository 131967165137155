import { Container, createStyles, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { SECTIONS } from "../../constants";
import Section from "./Section";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  }),
);

const Home: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Grid container justify="center" alignItems="stretch" spacing={1}>
          {SECTIONS.map((sectionType, index) => (
            <Grid item key={index}>
              <Section sectionType={sectionType} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Home;
