import React, { useContext, useReducer } from "react";

interface StoreState {
  darkMode: boolean | null;
}

interface SetDarkModeAction {
  type: "SET_DARK_MODE";
  data: boolean;
}

type StoreActionType = SetDarkModeAction;

interface StoreContext {
  state: StoreState;
  dispatch: (action: StoreActionType) => void;
}

const initialState: StoreState = {
  darkMode: null,
};

const reducer = (state: StoreState, action: StoreActionType): StoreState => {
  switch (action.type) {
    case "SET_DARK_MODE":
      return {
        darkMode: action.data,
      };
  }
};

const storeContext = React.createContext<StoreContext>({
  state: initialState,
  dispatch: () => {},
});

export const useStore = () => useContext<StoreContext>(storeContext);

export const StoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <storeContext.Provider value={{ state, dispatch }}>{children}</storeContext.Provider>;
};
