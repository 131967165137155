import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import App from "./App";
import "./i18n";
import { useStore } from "./store-provider";
import theme from "./theme";

const AppWrapper = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const {
    state: { darkMode },
  } = useStore();

  const darkTheme = darkMode ?? prefersDarkMode;

  return (
    <ThemeProvider theme={theme(darkTheme)}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};

export default AppWrapper;
