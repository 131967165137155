import { createStyles, InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback } from "react";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
  }),
);

interface TextInputProps {
  value: string;
  onChange: (value: string) => void;
}

const TextInput: React.FC<TextInputProps> = ({ value, onChange }) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return <InputBase className={classes.root} value={value} onChange={handleChange} multiline rows={4} fullWidth />;
};

export default React.memo(TextInput);
