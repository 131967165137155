// @ts-ignore
import resources from "@alienfast/i18next-loader!./i18n/"; // eslint-disable-line
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    ns: ["translations"],
    defaultNS: "translations",
    resources,
    detection: {
      order: ["htmlTag"],
    },
  });

export default i18n;
