import { BASE, NUMBER_TO_CHARACTER } from "./constants";

const MAP = {
  ...BASE.letters,
  ...BASE.numbers,
  ...BASE.punctuation,
};

const fromText = (inputString: string): string => {
  const letterToMorse = (value: number): string[] => {
    const binary: string = value.toString(2);
    const binaryArray = [...binary];
    return binaryArray.map(oneZero => NUMBER_TO_CHARACTER[oneZero]);
  };

  const result = [...inputString.toUpperCase()].map((letter: string) => {
    if (letter === " " || letter === "\n") return "/";
    if (letter in MAP) {
      const morseLetter = letterToMorse(MAP[letter]);
      morseLetter[0] = "/";
      return morseLetter.join("");
    }
    return "";
  });
  return result.join("").substring(1);
};

export const validateMorseTextInput = (value: string) => {
  return [...value]
    .filter(char => {
      const uC = char.toUpperCase();
      return MAP.hasOwnProperty(uC) || /\s/.test(uC);
    })
    .join("");
};

export default fromText;
