// TODO: remove usages of this function
import { SectionType } from "../pages/app-types";
import { validateMorseTextInput } from "./morse/fromText";
import { validateMorseCipherInput } from "./morse/toText";

export const notImplementedYet = () => window.alert("NOT implemented");

export const nullFunction = () => null;

const idFunction = <T>(value: T) => value;

const textFilterFunction: { [key in SectionType]: (value: string) => string } = {
  morse: validateMorseTextInput,
  braille: idFunction,
  "letter-number": idFunction,
  semaphore: idFunction,
  pigpen: idFunction,
  "signal-flags": idFunction,
};

const cipherFilterFunction: { [key in SectionType]: (value: string) => string } = {
  morse: validateMorseCipherInput,
  braille: idFunction,
  "letter-number": idFunction,
  semaphore: idFunction,
  pigpen: idFunction,
  "signal-flags": idFunction,
};

export const inputFilterFunction = {
  from: textFilterFunction,
  to: cipherFilterFunction,
};
