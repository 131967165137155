import { Card, CardActionArea, CardContent, createStyles, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as DarkBrailleIcon } from "../../img/codes/dark/braille.svg";
import { ReactComponent as DarkSignalFlagIcon } from "../../img/codes/dark/flag.svg";
import { ReactComponent as DarkLetterNumberIcon } from "../../img/codes/dark/letter-number.svg";
import { ReactComponent as DarkMorseIcon } from "../../img/codes/dark/morse.svg";
import { ReactComponent as DarkPigpenIcon } from "../../img/codes/dark/pigpen.svg";
import { ReactComponent as DarkSemaphoreIcon } from "../../img/codes/dark/semaphore.svg";
import { ReactComponent as BrailleIcon } from "../../img/codes/light/braille.svg";
import { ReactComponent as SignalFlagIcon } from "../../img/codes/light/flag.svg";
import { ReactComponent as LetterNumberIcon } from "../../img/codes/light/letter-number.svg";
import { ReactComponent as MorseIcon } from "../../img/codes/light/morse.svg";
import { ReactComponent as PigpenIcon } from "../../img/codes/light/pigpen.svg";
import { ReactComponent as SemaphoreIcon } from "../../img/codes/light/semaphore.svg";
import routes from "../../routes";
import { SectionType } from "../app-types";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 112,
      height: "100%",
    },
    action: {
      height: "100%",
    },
    media: {
      height: 90,
      marginBottom: "10px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
);

const IconMap: { [key in "dark" | "light"]: { [key in SectionType]: React.FC<React.SVGProps<SVGSVGElement>> } } = {
  light: {
    morse: MorseIcon,
    braille: BrailleIcon,
    "letter-number": LetterNumberIcon,
    semaphore: SemaphoreIcon,
    pigpen: PigpenIcon,
    "signal-flags": SignalFlagIcon,
  },
  dark: {
    morse: DarkMorseIcon,
    braille: DarkBrailleIcon,
    "letter-number": DarkLetterNumberIcon,
    semaphore: DarkSemaphoreIcon,
    pigpen: DarkPigpenIcon,
    "signal-flags": DarkSignalFlagIcon,
  },
};

interface SectionProps {
  sectionType: SectionType;
}

const Section: React.FC<SectionProps> = ({ sectionType }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const themeType = useTheme().palette.type;

  const Icon = IconMap[themeType][sectionType];
  const link = routes[sectionType];

  return (
    <Card className={classes.root}>
      <CardActionArea component={Link} to={link} className={classes.action}>
        <CardContent>
          <div className={classes.media}>
            <Icon width={60} />
          </div>
          <Typography variant="body2" component="h2">
            {t(`pages.home.sections.${sectionType}.title`)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Section;
