import _ from "lodash";

export const NUMBER_TO_CHARACTER: { [key: string]: string } = {
  "0": ".",
  "1": "-",
};

export const CHARACTER_TO_NUMBER = _.invert(NUMBER_TO_CHARACTER);

export const BASE: { [key in "letters" | "numbers" | "punctuation"]: { [key: string]: number } } = {
  letters: {
    A: 5,
    B: 24,
    C: 26,
    D: 12,
    E: 2,
    F: 18,
    G: 14,
    H: 16,
    I: 4,
    J: 23,
    K: 13,
    L: 20,
    M: 7,
    N: 6,
    O: 15,
    P: 22,
    Q: 29,
    R: 10,
    S: 8,
    T: 3,
    U: 9,
    V: 17,
    W: 11,
    X: 25,
    Y: 27,
    Z: 28,
  },
  numbers: {
    0: 63,
    1: 47,
    2: 39,
    3: 35,
    4: 33,
    5: 32,
    6: 48,
    7: 56,
    8: 60,
    9: 62,
  },
  punctuation: {
    ".": 85,
    ",": 115,
    "?": 76,
    "'": 94,
    "!": 107,
    "/": 50,
    "(": 54,
    ")": 109,
    "&": 40,
    ":": 120,
    ";": 106,
    "=": 49,
    "+": 42,
    "-": 97,
    _: 77,
    '"': 82,
    $: 137,
    "@": 90,
  },
};

export const I_BASE = {
  letters: _.invert(BASE.letters),
  numbers: _.invert(BASE.numbers),
  punctuation: _.invert(BASE.punctuation),
};
