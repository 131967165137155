import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import CipherPage from "./common/CipherPage";
import TopBar from "./common/TopBar";
import { SECTIONS } from "./constants";
import Copyright from "./Copyright";
import Error404 from "./pages/Error404";
import Home from "./pages/home/Home";
import routes from "./routes";

const App = () => {
  return (
    <div>
      <Switch>
        <Redirect exact from="/" to={routes.HOME} />
        <Route path={routes.HOME}>
          <TopBar />
          <Home />
        </Route>
        {SECTIONS.map((sectionType, index) => (
          <Route path={routes[sectionType]} key={index}>
            <TopBar codePage />
            <CipherPage sectionType={sectionType} />
          </Route>
        ))}
        <Route>
          <Error404 />
        </Route>
      </Switch>
      <Container maxWidth="sm">
        <Box my={4}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
};

export default App;
